import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import { useMediaQuery } from "usehooks-ts"
import { CardSkill } from "../CardSkill"
import { useEffect } from "react"
import { useHomeStore } from "store/home/useHomeStore"

export const SkillsRandomizer = () => {
  const cards = useHomeStore((state) => state.cards)
  const setCards = useHomeStore((state) => state.setCards)
  const isMobile = useMediaQuery("(max-width: 900px)")

  useEffect(() => {
    setCards()
  }, [setCards])

  if (isMobile) {
    return (
      <Swiper slidesPerView="auto" style={{ width: "90vw" }} centeredSlides>
        {cards.map((skill) => (
          <SwiperSlide key={skill.taelorInput}>
            <CardSkill card={skill} />
          </SwiperSlide>
        ))}
      </Swiper>
    )
  }

  return (
    <div className="flex w-full justify-center gap-4">
      {cards.map((skill) => (
        <CardSkill key={skill.taelorInput} card={skill} />
      ))}
    </div>
  )
}
