import mixpanel from "mixpanel-browser"

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "", {
  track_pageview: true,
})

export const Mixpanel = {
  identify: (id?: string) => mixpanel.identify(id),
  alias: (id: string) => mixpanel.alias(id),
  track: (name: string, props?: object) => mixpanel.track(name, props),
  people: {
    set: (props: object) => mixpanel.people.set(props),
  },
}
