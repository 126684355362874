import { Tooltip } from "components/Tooltip"
import { MdMenu, MdOutline360 } from "react-icons/md"
import { TbMessage2Question } from "react-icons/tb"
import { Link } from "react-router-dom"
import { useAuthenticatedLayoutStore } from "store/auth-layout/useAuthenticatedLayoutStore"
import { useHomeStore } from "store/home/useHomeStore"

export const HomeLayoutHeader = () => {
  const menu = useAuthenticatedLayoutStore((state) => state.menu)
  const setMenu = useAuthenticatedLayoutStore((state) => state.setMenu)
  const setCards = useHomeStore((state) => state.setCards)

  const handleClickHistoryMenu = () => {
    if (menu === "CHATS_HISTORY") return setMenu("NONE")

    setMenu("CHATS_HISTORY")
  }

  return (
    <header className="grid h-[64px] w-full grid-cols-[1fr_138px_1fr] items-center justify-between gap-6 bg-[#101214] px-4 lg:grid-cols-2 lg:bg-transparent">
      <div className="w-fit">
        <Tooltip text="Menu" side="bottom">
          <div>
            <MdMenu
              size={24}
              className="cursor-pointer text-[#E9F2FF]"
              onClick={handleClickHistoryMenu}
            />
          </div>
        </Tooltip>
      </div>
      <div className="mx-auto lg:hidden">
        <Link to="/">
          <img src="/taelor-logo.svg" alt="Taelor logo" className="w-[138px]" />
        </Link>
      </div>
      <div className="flex items-center justify-end gap-3">
        <Tooltip text="Gerar insights e perguntas" side="bottom">
          <div className="flex size-[40px] items-center justify-center rounded-full bg-[#1D2125]">
            <MdOutline360
              size={24}
              className="cursor-pointer text-[#E9F2FF]"
              onClick={() => setCards()}
            />
          </div>
        </Tooltip>
        <Tooltip text="O que posso perguntar?" side="bottom">
          <div className="flex size-[40px] items-center justify-center rounded-full bg-[#1D2125]">
            <TbMessage2Question
              size={24}
              className="cursor-pointer text-[#E9F2FF]"
              onClick={() => setMenu("LIST_OF_QUESTIONS")}
            />
          </div>
        </Tooltip>
      </div>
    </header>
  )
}
