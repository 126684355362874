import { useCallback } from "react"

const useTenantClient = () => {
  const baseURL: string = `${process.env.REACT_APP_SHARED_API_ENDPOINT}/tenants`

  const fetchTenantParams = useCallback(
    (tenant: string) => {
      return fetch(`${baseURL}/${tenant}`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
          }

          return response.json()
        })
        .then((data) => {
          return data
        })
        .catch((error) => {
          throw new Error(error)
        })
    },
    [baseURL]
  )

  return useCallback(
    () => ({
      fetchTenantParams,
    }),
    [fetchTenantParams]
  )
}

export default useTenantClient
