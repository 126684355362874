import { useAuthenticatedLayoutStore } from "store/auth-layout/useAuthenticatedLayoutStore"
import { cn } from "utils/utils"
import { ChatHistory } from "../ChatHistory"

export const MobileSidebarHistory = () => {
  const menu = useAuthenticatedLayoutStore((state) => state.menu)
  const setMenu = useAuthenticatedLayoutStore((state) => state.setMenu)

  const css = cn("flex fixed top-0 left-0 h-full z-10", {
    flex: menu === "CHATS_HISTORY",
    "hidden invisible": menu !== "CHATS_HISTORY",
  })

  return (
    <div className={css}>
      <div
        className="h-full w-screen bg-black/50"
        onClick={() => setMenu("NONE")}
      ></div>
      <div className="fixed left-0 top-0 z-50 h-full w-[320px] bg-gradient-to-b from-[#16339E] to-[#161A1D] to-15% px-6 pt-6">
        <img
          src="/taelor-logo.svg"
          alt="Taelor logo"
          className="mb-[37px] w-[138px]"
        />
        <p className="mb-4 text-[#DEE4EA]">Menu</p>
        <p className="mb-2 text-[#C7D1DB]">Histórico</p>
        <ChatHistory />
      </div>
    </div>
  )
}
