import React from "react"
import ReactDOM from "react-dom/client"
import "styles/globals.css"
import reportWebVitals from "./reportWebVitals"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import routes from "routes"
import { ChatProvider } from "context/ChatProvider"
import { UserProvider } from "context/UserProvider"
import { AuthProvider } from "context/AuthProvider"
import Hotjar from "@hotjar/browser"
import "boot/i18n"
import { QueryClient, QueryClientProvider } from "react-query"

const queryClient = new QueryClient()

const siteId = 3660812
const hotjarVersion = 6

Hotjar.init(siteId, hotjarVersion)

const router = createBrowserRouter(routes, { basename: process.env.PUBLIC_URL })

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <ChatProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ChatProvider>
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
