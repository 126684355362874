export const categories = [
  "Downtime",
  "Lead time",
  "Todos",
  "Plano de produção",
  "Manutenção",
  "Taxa de produção",
  "Setup",
]

export const questions = [
  {
    title: "Disponibilidade",
    input: "Avalie a disponibilidade do equipamento",
    category: "Downtime",
  },
  {
    title: "Gargalo",
    input: "Verifique qual o gargalo da unidade industrial ",
    category: "Lead time",
  },
  {
    title: "Gemba",
    input: "Realize uma análise de gemba para o equipamento ",
    category: "Todos",
  },
  {
    title: "Gestão de ritmo",
    input: "A unidade x irá atender a demanda mensal?",
    category: "Plano de produção",
  },
  {
    title: "Lead time",
    input: "Realize uma análise de lead time para a unidade industrial ",
    category: "Lead time",
  },
  {
    title: "Manutenção",
    input: "Avalie os indicadores de manutenção do equipamento ",
    category: "Manutenção",
  },
  {
    title: "Performance de equipamento",
    input: "Quais equipamentos estão reduzindo a performance da unidade?",
    category: "Taxa de produção",
  },
  {
    title: "Performance de produto",
    input:
      "A performance do equipamento está sendo impactada por quais produtos?",
    category: "Taxa de produção",
  },
  {
    title: "Quantidade produzida",
    input: "Avalie a produção do equipamento x.",
    category: "Taxa de produção",
  },
  {
    title: "Setup",
    input: "Quais os resultados de setup para o equipamento x?",
    category: "Setup",
  },
]
