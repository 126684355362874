import { User } from "utils/User"
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useAuth } from "./AuthProvider"

type UserContextType = {
  currentUser?: User
  currentTenant?: string
  setCurrentUser: (user: User) => void
  clearCurrentUser: () => void
}

// Create a new context
const UserContext = createContext<UserContextType | undefined>(undefined)

// Create a provider component
export function UserProvider({ children }: { children: React.ReactNode }) {
  // let mock = {
  //     id: 1,
  //     name: "Reginaldo Ribeiro",
  //     profile_picture_url: "https://files.cogtive.com.br/profile_picture/18dee5d281654f60b606ffe4f34ab47f.jpg"
  // }

  const [currentUser, setCurrentUser] = useState<User>()
  const [currentTenant] = useState<string>()

  const { token, initialized } = useAuth()

  const clearCurrentUser = useCallback(() => {
    setCurrentUser(undefined)
  }, [])

  const fetchCurrentUser = useCallback(() => {
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/current`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            window.location.href = "/login"
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`)
          }
        }

        return response.json()
      })
      .then((data) => {
        return {
          id: data.Id,
          name: data.Name,
          profile_picture_url: data.profilePictureUrl,
          showWelcomePage: data.show_welcome_page,
        } as User
        // setCurrentUser();
      })
      .catch((error) => {
        throw new Error(error)
      })
  }, [token])

  useEffect(() => {
    if (!initialized) return

    const currentPage = window.location.pathname

    if (!currentUser && currentPage !== "/login") {
      fetchCurrentUser().then((user) => setCurrentUser(user))
    }
  }, [initialized, currentUser, token, fetchCurrentUser])

  return (
    <UserContext.Provider
      value={{ currentUser, currentTenant, setCurrentUser, clearCurrentUser }}
    >
      {children}
    </UserContext.Provider>
  )
}

// Create a custom hook to use the context
export function useUser() {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error("useUser must be used within a UserProvider")
  }
  return context
}
