import { create } from "zustand"

export type MenuType = "CHATS_HISTORY" | "LIST_OF_QUESTIONS" | "NONE"

type State = {
  menu: MenuType
  setMenu: (menu: MenuType) => void
}

export const useAuthenticatedLayoutStore = create<State>((set) => ({
  menu: "NONE",
  setMenu: (menu: MenuType) => set(() => ({ menu })),
}))
