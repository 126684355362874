import ProfilePicture from "components/profile_picture/ProfilePicture"
import { useUser } from "context/UserProvider"
import { useMediaQuery } from "usehooks-ts"

type Props = {
  content: string
}

export default function UserMessageContent({ content }: Props) {
  const isMobile = useMediaQuery("(max-width: 1024px)")
  const { currentUser } = useUser()

  return (
    <>
      {currentUser ? (
        isMobile ? (
          <div
            className={`max-w-screen mx-4 mb-6 flex w-full max-w-4xl flex-col justify-end`}
          >
            <div className="flex items-center gap-2">
              <ProfilePicture user={currentUser} />
              <p className="text-xs text-white">{currentUser.name}</p>
            </div>
            <div className="mt-2 overflow-x-auto">
              <div className="w-full max-w-4xl rounded-[6px] bg-[#0C66E4] p-4">
                <p className="whitespace-pre-line text-sm text-white">
                  {content}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="max-w-screen relative mx-4 mb-[32px] flex w-full max-w-4xl flex-col">
            <div className="flex flex-col">
              <div className={`mb-2 flex items-center gap-2 transition-colors`}>
                <ProfilePicture user={currentUser} />
                <p className="text-xs text-white">
                  {currentUser.name?.split(" ")[0]}
                </p>
              </div>
              <div className="overflow-x-auto">
                <div className="w-fit max-w-4xl pl-10">
                  <p className="whitespace-pre-line rounded-[6px] bg-[#0C66E4] p-4 text-sm text-white">
                    {content}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      ) : null}
    </>
  )
}
