import { ReactNode } from "react"

export const WelcomeLayout = ({ children }: { children: ReactNode }) => {
  return (
    <main className="h-screen overflow-x-hidden bg-gradient-to-br from-[#161A1D] via-[#16339E] to-[#161A1D] lg:h-screen">
      <div className="flex h-[62px] w-screen items-center justify-start gap-2 px-4 lg:hidden">
        <img src="/taelor-logo.svg" alt="Taelor logo" className="w-[113px]" />
        <div className="flex h-[21px] items-center justify-center rounded-md bg-[#3F8CFE] px-1 text-xs text-white">
          beta
        </div>
      </div>
      {children}
    </main>
  )
}
