import { cn } from "utils/utils"
import { CicleInterestProps } from "./types"

export const CircleInterest = ({ interest, onClick }: CicleInterestProps) => {
  const css = cn(
    "text-xs font-medium text-[#DEE4EA] h-[100px] w-[100px] rounded-full flex flex-col items-center justify-center cursor-pointer transition ease-in-out",
    {
      "scale-110": interest.amount === 1,
      "scale-125": interest.amount === 2,
      "scale-[1.35]": interest.amount === 3,
    }
  )

  return (
    <div
      key={interest.name}
      className={css}
      style={
        interest.amount === 3
          ? {
              background: `linear-gradient(325deg, #05C 10.4%, rgba(6, 67, 171, 0.33) 47.32%, rgba(34, 80, 130, 0.40) 66.47%, #CCE0FF 88%), url(${interest.image}), lightgray 50% / cover no-repeat`,
              backgroundSize: "cover",
            }
          : {
              background:
                "linear-gradient(329deg, rgba(24, 43, 77, 0.97) 2.49%, rgba(9, 30, 66, 0.33) 45.73%, rgba(64, 84, 120, 0.46) 72.57%, #71777B 94.09%), var(--Dark-mode-Neutral-Dark-neutral-0, #161A1D)",
            }
      }
      onClick={() => onClick()}
    >
      <img src={interest.icon} alt="" className="mb-1 size-4" />
      <p className="select-none whitespace-break-spaces px-3 text-center">
        {interest.name}
      </p>
    </div>
  )
}
