import { SkillsRandomizer } from "components/home/SkillsRandomizer"
import { TagWelcome } from "components/home/TagWelcome"
import { AuthenticatedLayout } from "components/layouts/AuthenticatedLayout"
import { useAuth } from "context/AuthProvider"
import { useUser } from "context/UserProvider"
import { KeyboardEvent, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthenticatedLayoutStore } from "store/auth-layout/useAuthenticatedLayoutStore"
import { BsSend } from "react-icons/bs"
import { Mixpanel } from "utils/Mixpanel"
import { useMediaQuery } from "usehooks-ts"

export default function Home() {
  const isMobile = useMediaQuery("(max-width: 1024px)")
  const [fakeLoading, setFakeLoading] = useState<boolean>(true)
  const ref = useRef<HTMLInputElement>(null)
  const user = useUser()
  const navigate = useNavigate()
  const setMenu = useAuthenticatedLayoutStore((state) => state.setMenu)
  const { initialized } = useAuth()

  useEffect(() => {
    if (user.currentUser?.id) {
      Mixpanel.identify(String(user.currentUser?.id))
    }

    if (user.currentUser?.showWelcomePage) navigate("/welcome")
  }, [navigate, user.currentUser?.showWelcomePage, user.currentUser?.id])

  useEffect(() => {
    setMenu("NONE")
  }, [setMenu])

  useEffect(() => {
    setTimeout(() => {
      setFakeLoading(false)
    }, 2500)
  }, [])

  const handleSubmit = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const target = event.target as HTMLInputElement

      if (target.value.trim() !== "") navigate(`/chat?input=${target.value}`)
    }
  }

  const handleClick = () => {
    if (ref.current) {
      if (ref.current.value.trim() !== "")
        navigate(`/chat?input=${ref.current.value}`)
    }
  }

  if (user.currentUser !== undefined && !user.currentUser.showWelcomePage) {
    return (
      <>
        {initialized ? (
          <AuthenticatedLayout>
            <div
              className={`flex flex-col ${isMobile ? "h-[calc(100vh-64px)]" : "h-[calc(100vh-160px)]"}`}
            >
              <div className="mt-10 flex flex-col items-center">
                <TagWelcome />
                <p className="mb-[32px] mt-4 text-center text-[24px] text-[#DEE4EA] lg:text-[42px] lg:font-medium">
                  O <span className="home-text-gradient">futuro</span> da sua
                  empresa, <br /> começa agora.
                </p>
              </div>
              {fakeLoading ? (
                <div className="flex flex-col items-center mx-auto">
                  <span className="loader"></span>
                  <p className="mt-[18px] px-4 text-center text-lg text-[#DEE4EA] md:w-[380px]">
                    Estou gerando insights e perguntas baseadas na calibragem do
                    seuperfil para começarmos.
                  </p>
                </div>
              ) : (
                <>
                  <p className="mb-4 px-4 text-center text-lg text-[#DEE4EA] lg:mb-[25px]">
                    Trouxemos insights e perguntas baseados no seu perfil,
                    selecione qual <br /> gostaria de mais detalhes para
                    continuarmos:
                  </p>
                  <div className="my-auto">
                    <SkillsRandomizer />
                  </div>
                  <div className="relative mx-auto mt-4 w-[95vw] pb-8 lg:mt-[35px] lg:w-full lg:px-0">
                    <input
                      ref={ref}
                      className="h-[61px] w-full rounded-xl bg-[#22272B] px-4 pr-[65px] text-white"
                      placeholder="Pergunte me algo"
                      onKeyDown={handleSubmit}
                    />
                    <div
                      onClick={handleClick}
                      className="absolute right-4 top-3 flex size-[40px] cursor-pointer items-center justify-center rounded-full bg-[#282E33]"
                    >
                      <BsSend size={16} className="text-[#DEE4EA]" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </AuthenticatedLayout>
        ) : null}
      </>
    )
  }

  return (
    <div className="flex h-screen items-center justify-center overflow-x-hidden bg-gradient-to-br from-[#161A1D] via-[#16339E] to-[#161A1D]">
      <div className="flex w-screen flex-col items-center justify-center gap-2">
        <div className="mb-4 flex gap-2">
          <img src="/taelor-logo.svg" alt="Taelor logo" className="w-[158px]" />
          <div className="flex h-[21px] items-center justify-center rounded-md bg-[#3F8CFE] px-1 text-xs text-white">
            beta
          </div>
        </div>
        <span className="loader"></span>
      </div>
    </div>
  )
}
