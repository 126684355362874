import React, { PropsWithChildren, useEffect, useState } from "react"
// import secureLocalStorage from "react-secure-storage";

const defaultContext = {
  token: "",
  initialized: false,
  addToken: () => {},
  clearToken: () => {},
}

const AuthContext = React.createContext<{
  token: string
  initialized: boolean
  addToken: (token: string) => void
  clearToken: () => void
}>(defaultContext)

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [token, setToken] = React.useState("")
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem("token") as string

    if (token) {
      setToken(token)
    }

    setInitialized(true)
  }, [])

  const addToken = (token: string) => {
    setToken(token)
    localStorage.setItem("token", token)
  }

  const clearToken = () => {
    setToken("")
    localStorage.removeItem("token")
  }

  const value = React.useMemo(
    () => ({ token, initialized, addToken, clearToken }),
    [token, initialized]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => React.useContext(AuthContext)
