import { HTTPClient } from "client/HTTP"
import { useQuery } from "react-query"
import { ChatsResponse } from "types/Chat"

export const useChats = () => {
  return useQuery<ChatsResponse, Error>(
    "chats",
    async () => await HTTPClient.get("chats").json<ChatsResponse>()
  )
}
