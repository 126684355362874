import { ReactNode } from "react"

export const OnboardingLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="h-screen overflow-x-hidden bg-gradient-to-br from-[#161A1D] via-[#16339E] to-[#161A1D] py-4 xl:pt-0">
      <div className="mb-8 hidden h-[62px] w-screen items-center justify-center gap-2 bg-[#161A1D] xl:flex">
        <img src="/taelor-logo.svg" alt="Taelor logo" className="w-[158px]" />
        <div className="flex h-[21px] items-center justify-center rounded-md bg-[#3F8CFE] px-1 text-xs text-white">
          beta
        </div>
      </div>
      <div className="mx-4 flex h-full flex-col justify-items-end rounded-2xl bg-[#1D2125] pt-7 xl:mx-auto xl:h-fit xl:w-[1080px] xl:pt-8">
        <div className="max-w-screen mb-8 flex h-[70px] items-center gap-2 px-4 xl:hidden">
          <img src="/taelor-logo.svg" alt="Taelor logo" />
          <div className="rounded-md bg-[#3F8CFE] p-1 text-xs text-white">
            beta
          </div>
        </div>
        <div className="flex h-full flex-col">{children}</div>
      </div>
    </div>
  )
}
