import { useAuthenticatedLayoutStore } from "store/auth-layout/useAuthenticatedLayoutStore"
import { cn } from "utils/utils"
import { categories, questions } from "./utils"
import { TagQuestionCategory } from "./components/TagQuestionCategory"
import { MdClose } from "react-icons/md"
import { useState } from "react"
import { FeedbackEmptyList } from "./components/FeedbackEmptyList"
import { CardQuestion } from "./components/CardQuestion"
import { SelectCategories } from "./components/SelectCategories"

export const SidebarQuestionsList = () => {
  const menu = useAuthenticatedLayoutStore((state) => state.menu)
  const setMenu = useAuthenticatedLayoutStore((state) => state.setMenu)
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [foundQuestions, setFoundQuestions] = useState<
    (typeof questions)[number][]
  >([])

  const css = cn("flex fixed lg:absolute top-0 right-0 h-full z-10", {
    flex: menu === "LIST_OF_QUESTIONS",
    "hidden invisible": menu !== "LIST_OF_QUESTIONS",
  })

  const cardsContainerCSS = cn("h-[70%] gap-3 flex flex-col justify-center", {
    "justify-center": selectedCategories.length === 0,
    "justify-start": selectedCategories.length > 0,
  })

  const handleOnChange = (value: string) => {
    if (!selectedCategories.includes(value)) {
      setSelectedCategories((prevState) => [...prevState, value])
    } else {
      setSelectedCategories((prevState) =>
        prevState.filter((category) => category !== value)
      )
    }
  }

  const handleOnClickTag = (value: string) => {
    setSelectedCategories((prevState) =>
      prevState.filter((category) => category !== value)
    )
  }

  const filterQuestions = () => {
    setFoundQuestions(
      questions.filter((question) =>
        selectedCategories.includes(question.category)
      )
    )
  }

  return (
    <div className={css}>
      <div className="fixed right-0 top-0 size-full bg-[#161A1D] p-6 lg:absolute lg:w-[450px] lg:rounded-r-2xl">
        <div className="mb-2 flex items-center justify-between">
          <p className="text-[#DEE4EA]">O que posso perguntar?</p>
          <MdClose
            size={24}
            className="cursor-pointer text-[#ECEEF1]"
            onClick={() => setMenu("NONE")}
          />
        </div>
        <p className="mb-6 text-sm text-[#DEE4EA]">
          Para visualizar as perguntas, selecione uma categoria abaixo e clique
          na que deseja que o TAELOR responda.
        </p>
        <div className="mb-4 grid w-full grid-cols-[1fr_64px] items-center gap-2">
          <SelectCategories
            options={categories.map((cat) => ({ label: cat, value: cat }))}
            onChange={handleOnChange}
            selectedCategories={selectedCategories}
          />
          <button
            className="flex h-[40px] items-center justify-center rounded-lg bg-[#579DFF] text-xs text-[#1D2125]"
            onClick={() => filterQuestions()}
          >
            Filtrar
          </button>
        </div>
        {selectedCategories.length > 0 && (
          <div className="mb-7">
            <p className="text-xs text-[#9FADBC]">Selecionados</p>
            <div className="flex h-12 w-full items-center gap-2 overflow-x-scroll scrollbar-none">
              {selectedCategories.map((category) => (
                <TagQuestionCategory
                  category={category}
                  key={category}
                  onClick={handleOnClickTag}
                />
              ))}
            </div>
          </div>
        )}
        <div className={cardsContainerCSS}>
          {foundQuestions.length > 0 && (
            <>
              <p className="mb-2 text-xs text-[#9FADBC]">
                {foundQuestions.length} pergunta
                {foundQuestions.length > 1 ? "s" : ""} encontrada
                {foundQuestions.length > 1 ? "s" : ""}
              </p>
              <div className="flex h-[90%] flex-col gap-4 overflow-y-scroll scrollbar-none">
                {foundQuestions.map((question) => (
                  <CardQuestion
                    key={question.title}
                    title={question.title}
                    input={question.input}
                    category={question.category}
                  />
                ))}
              </div>
            </>
          )}
          {selectedCategories.length === 0 && foundQuestions.length === 0 && (
            <FeedbackEmptyList />
          )}
        </div>
      </div>
    </div>
  )
}
