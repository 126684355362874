import { CardProfile } from "../CardProfile"
import { ButtonOnboarding } from "../ButtonOnboarding"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "./styles.css"
import { useOnboardingStore } from "store/onboarding/useOnboardingStore"
import { profiles } from "./utils"
import { useTranslation } from "react-i18next"
import { Mixpanel } from "utils/Mixpanel"

export const StepProfileCalibration = () => {
  const { t } = useTranslation()
  const selectedProfile = useOnboardingStore((state) => state.selectedProfile)
  const setCurrentStep = useOnboardingStore((state) => state.setCurrentStep)
  const setSelectedProfile = useOnboardingStore(
    (state) => state.setSelectedProfile
  )

  const handleExitOnboarding = () => {
    setSelectedProfile("NONE")
    setCurrentStep("STEP_WELCOME")
  }

  const handleNextStep = () => {
    Mixpanel.track("PROFILE_SELECTION", { type: selectedProfile })
    setCurrentStep("STEP_INTEREST_FREQUENCY")
  }

  return (
    <div className="flex h-full flex-col">
      <div className="mb-[42px] hidden px-4 xl:block xl:px-8">
        <div className="mb-2 flex items-center justify-between text-sm text-[#B6C2CF]">
          <p>{t("onboarding.progressBarStep1Label")}</p>
          <p>
            1 {t("onboarding.progressBarSteps.part1")} 2{" "}
            {t("onboarding.progressBarSteps.part2")}
          </p>
        </div>
        <div className="flex w-full items-center justify-center gap-2">
          <div className="h-[5px] w-full rounded-[5px] bg-[#1D7AFC]" />
          <div className="h-[5px] w-full rounded-[5px] bg-[#38414A]" />
        </div>
      </div>
      <div className="px-4 xl:px-8">
        <p className="mb-3 text-sm font-medium text-[#B6C2CF] md:text-lg">
          {t("onboarding.performanceProfile.part1")}
        </p>
        <p className="text-lg font-semibold text-[#C7D1DB] md:text-2xl">
          {t("onboarding.performanceProfile.part2")}
        </p>
        <p className="mb-8 text-sm text-[#DEE4EA] md:mb-[55px] md:text-base">
          {t("onboarding.performanceProfile.part3")}
        </p>
      </div>
      <Swiper
        slidesPerView="auto"
        centeredSlides
        initialSlide={1}
        breakpoints={{
          520: { centeredSlides: false },
          1024: { centeredSlides: false },
        }}
      >
        {profiles.map((profile) => (
          <SwiperSlide key={profile.type}>
            <CardProfile
              key={profile.type}
              name={profile.name}
              type={profile.type}
              summary={profile.summary}
              description={profile.description}
              image={profile.background}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="mt-auto">
        <div className="mb-auto px-4 xl:hidden">
          <div className="mb-2 flex items-center justify-between text-sm text-[#B6C2CF]">
            <p>{t("onboarding.progressBarStep1Label")}</p>
            <div className="flex w-[45px] items-center justify-center gap-2">
              <div className="h-[5px] w-full rounded-[5px] bg-[#1D7AFC]" />
              <div className="h-[5px] w-full rounded-[5px] bg-[#38414A]" />
            </div>
          </div>
        </div>
        <div className="flex h-[75px] w-full items-center justify-between gap-6 rounded-b-2xl bg-[#22272B] px-4 xl:px-8">
          <ButtonOnboarding side="left" onClick={handleExitOnboarding}>
            {t("onboarding.exitButton")}
          </ButtonOnboarding>
          <ButtonOnboarding
            side="right"
            disabled={selectedProfile === "NONE"}
            onClick={handleNextStep}
          >
            {t("onboarding.advanceButton")}
          </ButtonOnboarding>
        </div>
      </div>
    </div>
  )
}
