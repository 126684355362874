import { BsChevronRight } from "react-icons/bs"
import { CardQuestionProps } from "./types"
import { Link } from "react-router-dom"

export const CardQuestion = ({ title, input, category }: CardQuestionProps) => {
  return (
    <Link to={`/chat?input=${encodeURIComponent(input)}`}>
      <div className="cursor-pointer rounded-lg border-2 border-[#22272B] bg-[#22272B] p-4 shadow-lg hover:border-[#579DFF]">
        <div className="mb-3 flex items-center justify-between">
          <div>
            <p className="mb-1 font-bold text-[#DEE4EA]">{title}</p>
            <p className="text-xs text-[#8C9BAB]">{category}</p>
          </div>
          <div className="bf-[#579DFF] flex size-[32px] items-center justify-center rounded-full bg-[#579DFF]">
            <BsChevronRight size={16} className="text-[#1D2125]" />
          </div>
        </div>
        <p className="text-[#9FADBC]">{input}</p>
      </div>
    </Link>
  )
}
