import { useAuthenticatedLayoutStore } from "store/auth-layout/useAuthenticatedLayoutStore"
import { cn } from "utils/utils"
import { ChatHistory } from "../ChatHistory"

export const DesktopSidebarHistory = () => {
  const menu = useAuthenticatedLayoutStore((state) => state.menu)

  const css = cn("w-[192px]", {
    "flex flex-col": menu === "CHATS_HISTORY",
    "hidden invisible": menu !== "CHATS_HISTORY",
  })

  return (
    <div className={css}>
      <p className="mb-4 text-[#DEE4EA]">Menu</p>
      <p className="mb-4 text-[#C7D1DB]">Histórico</p>
      <ChatHistory />
    </div>
  )
}
