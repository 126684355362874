import React, { useEffect, useState } from "react"
import ChatInput from "./ChatInput"
import ChatMessage from "./ChatMessage"
import { useChat } from "context/ChatProvider"
import { useNavigate } from "react-router-dom"
import { useUser } from "context/UserProvider"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "usehooks-ts"

export default function ChatMessages() {
  const isMobile = useMediaQuery("(max-width: 1024px)")
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentChat, sendMessage } = useChat()
  const messageContainer = React.useRef<HTMLDivElement>(null)
  const messageEndRef = React.useRef<HTMLDivElement>(null)
  const [scrolling] = React.useState(false)
  const [prevMessageLength, setPrevMessageLength] = React.useState(0)
  const [loadingResponse, setLoadingResponse] = useState<boolean>(false)
  const { currentUser } = useUser()
  const [hasError, setHasError] = useState(false)

  const scrollToBottom = (behavior: ScrollBehavior = "instant") => {
    messageEndRef.current?.scrollIntoView({ behavior: behavior })
  }

  useEffect(() => {
    if (!currentChat) return

    const { messages } = currentChat

    if (messages.length !== prevMessageLength) {
      setPrevMessageLength(messages.length)
    }

    if (
      messageContainer.current &&
      (!scrolling || messages.length !== prevMessageLength || hasError)
    ) {
      messageContainer.current.scrollTop = messageContainer.current.scrollHeight
    }
  }, [
    currentChat,
    hasError,
    currentChat?.messages,
    scrolling,
    prevMessageLength,
  ])

  const handleSubmit = (msg: string) => {
    setLoadingResponse(true)
    setHasError(false)

    sendMessage(msg)
      .then((response) => {
        if (response.isNewChat) {
          navigate(`/chat/${response.id}`)
        }
      })
      .catch(() => setHasError(true))
      .finally(() => {
        setLoadingResponse(false)
      })
  }

  return (
    <>
      <div
        className={`flex min-w-full flex-col items-stretch ${isMobile ? "h-[calc(100vh-64px)]" : "h-[calc(100vh-160px)]"}`}
      >
        <div
          className={`relative flex grow flex-col items-stretch overflow-auto pt-4 scrollbar-none`}
          ref={messageContainer}
        >
          {currentChat && currentUser ? (
            <>
              {currentChat.messages.map((message, index) => {
                return (
                  <ChatMessage
                    key={index}
                    message={message}
                    scrollFn={scrollToBottom}
                  />
                )
              })}
              <div
                style={{ float: "left", clear: "both" }}
                ref={messageEndRef}
              ></div>
            </>
          ) : null}
          {hasError ? (
            <div className="flex flex-row items-center px-4 transition-all ">
              <div
                className={`${
                  isMobile ? "" : "pl-6 pr-2"
                } max-w-screen relative mx-auto flex w-full max-w-4xl flex-row items-center`}
              >
                <div className="w-full rounded-lg bg-red-500 px-4 py-2 font-medium text-white">
                  <span>{t("errors.requestError")}</span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <ChatInput loading={loadingResponse} onSubmitMessage={handleSubmit} />
      </div>
    </>
  )
}
