import { OnboardingLayout } from "components/layouts/OnboardingLayout"
import { WelcomeLayout } from "components/layouts/WelcomeLayout"
import { StepProfileCalibration } from "components/onboarding/StepProfileCalibration"
import { StepInterestFrequency } from "components/onboarding/StepInterestFrequency"
import { ReactNode } from "react"
import {
  useOnboardingStore,
  OnboardingStep,
} from "store/onboarding/useOnboardingStore"
import { StepWelcome } from "components/onboarding/StepWelcome"

export default function Onboarding() {
  const currentStep = useOnboardingStore((state) => state.currentStep)

  const OnboardingSteps = ({
    currentStep,
  }: {
    currentStep: OnboardingStep
  }) => {
    const steps: Record<OnboardingStep, ReactNode> = {
      STEP_WELCOME: <StepWelcome />,
      STEP_PROFILE_CALIBRATION: <StepProfileCalibration />,
      STEP_INTEREST_FREQUENCY: <StepInterestFrequency />,
    }

    return steps[currentStep]
  }

  const Layout =
    currentStep === "STEP_WELCOME" ? WelcomeLayout : OnboardingLayout

  return (
    <Layout>
      <OnboardingSteps currentStep={currentStep} />
    </Layout>
  )
}
