import { MdClose } from "react-icons/md"
import { TagQuestionCategoryProps } from "./types"
import { BsClock } from "react-icons/bs"
import { useState } from "react"

export const TagQuestionCategory = ({
  category,
  onClick,
}: TagQuestionCategoryProps) => {
  const [visible, setVisible] = useState<boolean>(true)

  const handleOnClick = () => {
    onClick?.(category)
    setVisible(false)
  }

  if (!visible) return null

  return (
    <div className="flex h-6 items-center gap-2 rounded-2xl bg-[#CCE0FF] px-2">
      <BsClock size={16} className="text-[#0055CC]" />
      <span className="whitespace-nowrap text-xs">{category}</span>
      <MdClose
        size={16}
        className="cursor-pointer text-[#0055CC]"
        onClick={() => handleOnClick()}
      />
    </div>
  )
}
