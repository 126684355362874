import { User } from "utils/User"

type Props = {
  user: User | undefined
  className?: string
}

export default function ProfilePicture({ user }: Props) {
  const abbreviatedName = () => {
    if (user?.name) {
      const splittedName = user.name?.split(" ")

      if (splittedName[0] && splittedName[1]) {
        return (splittedName[0][0] + splittedName[1][0]).toUpperCase()
      } else {
        return splittedName[0][0]
      }
    }

    return ""
  }

  return (
    <>
      {user?.profile_picture_url ? (
        <img
          alt={user.name}
          className="rounded-full"
          src={user.profile_picture_url}
        />
      ) : (
        <div className="flex size-[32px] items-center justify-center rounded-full bg-[#1D7AFC] font-medium">
          <span className="select-none text-white">{abbreviatedName()}</span>
        </div>
      )}
    </>
  )
}
