import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

// Capitalize words
export const capitalize = (str: string = "") => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]))
  } catch (e) {
    return null
  }
}

export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args))
}
