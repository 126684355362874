import ReactMarkdown from "react-markdown"
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism"

import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter"
import markdown from "react-syntax-highlighter/dist/cjs/languages/prism/markdown"
import MathJax from "react-mathjax"
import remarkMath from "remark-math"
import rehypeKatex from "rehype-katex"
import remarkGfm from "remark-gfm"

import "katex/dist/katex.min.css" // `rehype-katex` does not import the CSS for you
import { useOutletContext } from "react-router-dom"
import { ContextType } from "routes/root"
import BonusTip from "./BonusTip"
import { useEffect, useState } from "react"
import ChatFeedback from "./ChatFeedback"
import { OpenAIChatMessage } from "utils/OpenAI"

SyntaxHighlighter.registerLanguage("markdown", markdown)

const syntaxTheme = oneDark

type Props = {
  message: OpenAIChatMessage
  scrollFn?: () => void
}

export default function AssistantMessageContent({
  message: { content, id, feedback, typingEffect = false },
  scrollFn,
  ...props
}: Props) {
  const { isMobile } = useOutletContext<ContextType>()
  const [displayedText, setDisplayedText] = useState("")

  useEffect(() => {
    if (!typingEffect) {
      setDisplayedText(content)
      return
    }

    let currentIndex = 0
    const typingInterval = setInterval(() => {
      if (currentIndex <= content.length) {
        setDisplayedText(content.slice(0, currentIndex))
        currentIndex++

        if (scrollFn) {
          scrollFn()
        }
      } else {
        clearInterval(typingInterval)
      }
    }, 5) // Delay between each character

    return () => {
      clearInterval(typingInterval)
    }
  }, [typingEffect, content, scrollFn])

  const MarkdownComponents: any = {
    // Work around for not rending <em> and <strong> tags
    em: ({ className, children, ...props }: any) => {
      return (
        <span className={`italic ${className}`} {...props}>
          {children}
        </span>
      )
    },
    strong: ({ className, children, ...props }: any) => {
      return (
        <span className={`font-bold ${className}`} {...props}>
          {children}
        </span>
      )
    },

    pre: ({ className, children, ...props }: any) => {
      return (
        <pre
          className={`m-0 overflow-auto whitespace-pre-wrap ${className || ""}`}
          {...props}
        >
          {children}
        </pre>
      )
    },

    math: (props: any) => <MathJax.Node formula={props.value} />,
    inlineMath: (props: any) => <MathJax.Node inline formula={props.value} />,

    code({ inline, className, children, ...props }: any) {
      const match = /language-(\w+)/.exec(className || "")

      const value = String(children).replace(/\n$/, "")

      if (!inline && match) {
        if (className === "language-bonustip")
          return <BonusTip content={<ReactMarkdown>{value}</ReactMarkdown>} />

        return (
          <SyntaxHighlighter
            {...props}
            children={value}
            style={syntaxTheme}
            language={match[1]}
            PreTag="div"
          />
        )
      } else {
        return (
          <code {...props} className={className}>
            {children}
          </code>
        )
      }
    },
  }

  return (
    <div className={`mx-4 mb-6 flex w-full max-w-4xl flex-col justify-start`}>
      {isMobile ? (
        <div
          className={`mb-2 flex size-[32px] items-center justify-center self-start text-4xl transition-colors`}
        >
          <img
            className="rounded-full"
            src="/chat/taelor-profile.png"
            alt="TÆLOR"
          />
        </div>
      ) : (
        <div
          className={`mb-4 flex items-center justify-center gap-2 self-start text-4xl transition-colors`}
        >
          <div>
            <img
              className="size-[32px] rounded-full"
              src="/chat/taelor-profile.png"
              alt="TÆLOR"
            />
          </div>
          <div className="flex items-center text-base">
            <p className="text-sm text-white">TÆLOR</p>
          </div>
        </div>
      )}
      <div className="overflow-x-auto">
        <div className={`assistant-msg w-full max-w-4xl rounded lg:pl-10`}>
          <div className="rounded-lg border border-[#0C66E4] bg-home/bg-card-skill p-4 text-sm text-white">
            <ReactMarkdown
              remarkPlugins={[remarkMath, remarkGfm]}
              rehypePlugins={[rehypeKatex]}
              components={MarkdownComponents}
              {...props}
            >
              {displayedText}
            </ReactMarkdown>
            {id && <ChatFeedback messageId={id} feedback={feedback} />}
          </div>
        </div>
      </div>
    </div>
  )
}
