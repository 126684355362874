export const VideoPlayer = ({
  src,
  onDurationChange,
  onEnded,
}: {
  src: string
  onDurationChange: React.Dispatch<React.SetStateAction<number>>
  onEnded: (ended: boolean) => void
}) => {
  const handleLoadedMetadata = (event: EventTarget) => {
    //@ts-expect-error EventTarget doesnt have the duration property
    const { duration } = event

    // Pass the duration back to the parent component
    if (onDurationChange) {
      onDurationChange(duration)
    }
  }

  return (
    <video
      key={src}
      autoPlay={true}
      muted
      style={{ overflow: "hidden", width: "100%" }}
      onEnded={() => onEnded(true)}
      onLoadedMetadata={(event) => handleLoadedMetadata(event.target)}
    >
      <source src={src} type="video/mp4" />
    </video>
  )
}
