import { useOnboardingStore } from "store/onboarding/useOnboardingStore"
import { ButtonOnboarding } from "../ButtonOnboarding"
import { CircleInterest } from "./components/CircleInterest"
import { useState } from "react"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "./styles.css"
import { useAuth } from "context/AuthProvider"
import { useNavigate } from "react-router-dom"
import { useUser } from "context/UserProvider"
import { useTranslation } from "react-i18next"
import { Mixpanel } from "utils/Mixpanel"

type State = { name: string; amount: number; icon: string; image: string }[]

export const StepInterestFrequency = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const setCurrentStep = useOnboardingStore((state) => state.setCurrentStep)
  const selectedProfile = useOnboardingStore((state) => state.selectedProfile)
  const { token } = useAuth()
  const user = useUser()
  const navigate = useNavigate()

  const [interests, setInterests] = useState<State>([
    {
      name: "Lead time",
      amount: 0,
      icon: "onboarding/hourglass-high.svg",
      image: "onboarding/skill-leadtime.png",
    },
    {
      name: "WIP",
      amount: 0,
      icon: "onboarding/tools.svg",
      image: "onboarding/skill-wip.png",
    },
    {
      name: t("onboarding.skillsFrequency.productionRateLabel"),
      amount: 0,
      icon: "onboarding/settings-automation.svg",
      image: "onboarding/skill-taxaproducao.png",
    },
    {
      name: t("onboarding.skillsFrequency.maintenanceLabel"),
      amount: 0,
      icon: "onboarding/settings-exclamation.svg",
      image: "onboarding/skill-manutencao.png",
    },
    {
      name: "Holding time",
      amount: 0,
      icon: "onboarding/clock-play.svg",
      image: "onboarding/skill-holdingtime.png",
    },
    {
      name: "Setup",
      amount: 0,
      icon: "onboarding/settings-search.svg",
      image: "onboarding/skill-setup.png",
    },
    {
      name: t("onboarding.skillsFrequency.routeDeviationLabel"),
      amount: 0,
      icon: "onboarding/arrows-double-ne-sw.svg",
      image: "onboarding/skill-desviorota.png",
    },
    {
      name: t("onboarding.skillsFrequency.inconsistencyLabel"),
      amount: 0,
      icon: "onboarding/exclamation-circle.svg",
      image: "onboarding/skill-inconsistencia.png",
    },
    {
      name: t("onboarding.skillsFrequency.productionPlanLabel"),
      amount: 0,
      icon: "onboarding/file-analytics.svg",
      image: "onboarding/skill-planoproducao.png",
    },
    {
      name: "Downtime",
      amount: 0,
      icon: "onboarding/world-pause.svg",
      image: "onboarding/skill-downtime.png",
    },
    {
      name: t("onboarding.skillsFrequency.cycleTimeLabel"),
      amount: 0,
      icon: "onboarding/tilt-shift.svg",
      image: "onboarding/skill-templociclo.png",
    },
  ])

  const handleOnClick = (name: string, amount: number) => {
    setInterests(
      interests.map((interest) => {
        if (interest.name === name) {
          return { ...interest, amount: amount === 3 ? 0 : ++amount }
        } else {
          return interest
        }
      })
    )
  }

  const handleSubmit = async (token: string) => {
    const skills = interests
      .filter((interest) => interest.amount > 0)
      .map((interest) => ({ name: interest.name, amount: interest.amount }))
    setLoading(true)

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }

    const welcomeResponse = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/users/welcome_page`,
      {
        headers,
        method: "POST",
      }
    )
    const interestsResponse = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/users/current`,
      {
        headers,
        method: "PATCH",
        body: JSON.stringify({
          profile: selectedProfile,
          interests: skills,
        }),
      }
    )

    if (welcomeResponse.ok && interestsResponse.ok) {
      const userResponse = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/users/current`,
        {
          headers,
          method: "GET",
        }
      )

      const json = await userResponse.json()

      user.setCurrentUser({
        id: json.Id,
        name: json.Name,
        profile_picture_url: json.profilePictureUrl,
        showWelcomePage: false,
      })

      Mixpanel.track("SKILLS_SELECTION", { skills })

      return navigate("/")
    }

    setLoading(false)
  }

  const firstRow = interests.slice(0, 4)
  const secondRow = interests.slice(4, 8)
  const thirdRow = interests.slice(8, 12)

  return (
    <div className="flex h-full flex-col rounded-b-2xl bg-[#1D2125]">
      <div className="mb-[42px] hidden px-4 xl:block xl:px-8">
        <div className="mb-2 flex items-center justify-between text-sm text-[#B6C2CF]">
          <p>{t("onboarding.progressBarStep1Label")}</p>
          <p>
            2 {t("onboarding.progressBarSteps.part1")} 2{" "}
            {t("onboarding.progressBarSteps.part2")}
          </p>
        </div>
        <div className="flex w-full items-center justify-center gap-2">
          <div className="h-[5px] w-full rounded-[5px] bg-[#1D7AFC]" />
          <div className="h-[5px] w-full rounded-[5px] bg-[#1D7AFC]" />
        </div>
      </div>
      <div className="mb-8 px-4 lg:mb-[80px] xl:px-8">
        <p className="mb-3 text-lg font-semibold text-[#C7D1DB] md:text-2xl">
          {t("onboarding.skillsFrequency.part1")}
        </p>
        <p className="text-sm text-[#DEE4EA]">
          {t("onboarding.skillsFrequency.part2")}
        </p>
      </div>
      <div className="relative h-full">
        <div className="flex flex-col px-4 xl:px-8">
          <Swiper
            wrapperClass="wrapper-skill"
            slidesPerView={2}
            className="swiper-skill"
            breakpoints={{ 600: { enabled: false } }}
          >
            {firstRow.map((interest) => (
              <SwiperSlide className="slide-skill" key={interest.name}>
                <CircleInterest
                  key={interest.name}
                  interest={interest}
                  onClick={() => handleOnClick(interest.name, interest.amount)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            wrapperClass="wrapper-skill"
            slidesPerView={2}
            className="swiper-skill"
            breakpoints={{ 600: { enabled: false } }}
          >
            {secondRow.map((interest) => (
              <SwiperSlide className="slide-skill" key={interest.name}>
                <CircleInterest
                  key={interest.name}
                  interest={interest}
                  onClick={() => handleOnClick(interest.name, interest.amount)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            wrapperClass="wrapper-skill"
            slidesPerView={2}
            className="swiper-skill"
            breakpoints={{ 600: { enabled: false } }}
          >
            {thirdRow.map((interest) => (
              <SwiperSlide className="slide-skill" key={interest.name}>
                <CircleInterest
                  key={interest.name}
                  interest={interest}
                  onClick={() => handleOnClick(interest.name, interest.amount)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="mt-auto">
        <div className="mb-auto px-4 xl:hidden">
          <div className="mb-2 flex items-center justify-between text-sm text-[#B6C2CF]">
            <p>{t("onboarding.progressBarStep1Label")}</p>
            <div className="flex w-[45px] items-center justify-center gap-2">
              <div className="h-[5px] w-full rounded-[5px] bg-[#1D7AFC]" />
              <div className="h-[5px] w-full rounded-[5px] bg-[#1D7AFC]" />
            </div>
          </div>
        </div>
        <div className="flex h-[75px] w-full items-center justify-between gap-6 rounded-b-2xl bg-[#22272B] px-4 xl:px-8">
          <ButtonOnboarding
            side="left"
            onClick={() => setCurrentStep("STEP_PROFILE_CALIBRATION")}
          >
            {t("onboarding.goBackButton")}
          </ButtonOnboarding>
          <ButtonOnboarding
            side="right"
            disabled={
              interests.every((interest) => interest.amount === 0) || loading
            }
            onClick={() => handleSubmit(token)}
          >
            {t("onboarding.submitButton")}
          </ButtonOnboarding>
        </div>
      </div>
    </div>
  )
}
