import { useOnboardingStore } from "store/onboarding/useOnboardingStore"
import { VideoDescription } from "./components/VideoDescription"
import { useState } from "react"
import { VideoPlayer } from "./components/VideoPlayer"
import { useMediaQuery } from "usehooks-ts"
import { useTranslation } from "react-i18next"

export const StepWelcome = () => {
  const { t } = useTranslation()
  const boxes = [
    {
      id: 1,
      icon: "onboarding/icon-box-1.svg",
      title: t("onboarding.video-1.title"),
      description: t("onboarding.video-1.description"),
      video: "onboarding/manu-desk.mp4",
      videoMobile: "onboarding/manu-mob.mp4",
    },
    {
      id: 2,
      icon: "onboarding/icon-box-2.svg",
      title: t("onboarding.video-2.title"),
      description: t("onboarding.video-2.description"),
      video: "onboarding/gargalo-desk.mp4",
      videoMobile: "onboarding/gargalo-mob.mp4",
    },
  ]
  const isMobile = useMediaQuery("(max-width: 1023px)")
  const setCurrentStep = useOnboardingStore((state) => state.setCurrentStep)
  const [video, setVideo] = useState(boxes[0])
  const [totalTime, setTotalTime] = useState(0)

  const handleOnClick = async () => {
    setCurrentStep("STEP_PROFILE_CALIBRATION")
  }

  const handleOnEnded = (ended: boolean) => {
    if (ended) {
      if (video.id === 1) {
        setVideo(boxes[1])
      } else {
        setVideo(boxes[0])
      }
    }
  }

  return (
    <div className="grid-cols-1 justify-center gap-[60px] md:grid-cols-2 lg:grid lg:h-full lg:px-[68px] lg:pt-[100px] 2xl:grid-cols-[515px_1fr]">
      <div className="flex h-[90px] flex-col px-4 pb-4 lg:h-[1110px] lg:px-0">
        <div className="mb-[120px] hidden h-[62px] w-full items-center justify-start gap-2 lg:flex">
          <img src="/taelor-logo.svg" alt="Taelor logo" className="w-[205px]" />
          <div className="flex h-[21px] items-center justify-center rounded-md bg-[#3F8CFE] px-1 text-xs text-white">
            beta
          </div>
        </div>
        <div className="mb-[60px] text-white">
          <p className="text-lg font-bold lg:mb-6 lg:text-5xl">
            {t("onboarding.heading")}
          </p>
          <p className="text-sm lg:text-lg">
            <span className="sr-only lg:not-sr-only">
              {t("onboarding.subheading.part1")}
            </span>{" "}
            {t("onboarding.subheading.part2")}
          </p>
        </div>
        <div className="mb-4 hidden lg:block">
          <VideoDescription
            title={video.title}
            description={video.description}
            icon={video.icon}
            time={totalTime}
          />
        </div>
        <button
          onClick={() => handleOnClick()}
          className="mt-[100px] hidden h-14 w-full items-center justify-center rounded-lg bg-[#0C66E4] text-[#F7F8F9] lg:flex 2xl:mt-[163px]"
        >
          <div>
            {t("onboarding.welcomeButton")}
            <span className="font-bold">TÆLOR</span>
          </div>
        </button>
      </div>
      <div className="relative h-full px-4 lg:px-0">
        <div className="h-[320px] w-full overflow-hidden rounded-t-2xl bg-red-50 md:h-[640px] lg:h-fit lg:w-[980px] lg:rounded-2xl 2xl:absolute 2xl:right-[-120px] 2xl:top-0 min-[1700px]:w-[1100px]">
          <VideoPlayer
            src={isMobile ? video.videoMobile : video.video}
            onDurationChange={setTotalTime}
            onEnded={handleOnEnded}
          />
        </div>
        <div className="lg:hidden">
          <VideoDescription
            title={video.title}
            description={video.description}
            icon={video.icon}
            time={totalTime}
          />
        </div>
      </div>
      <div className="my-4 px-4 lg:hidden">
        <button
          onClick={() => handleOnClick()}
          className="flex h-14 w-full items-center justify-center rounded-lg bg-[#0C66E4] text-[#F7F8F9] lg:hidden"
        >
          <div>
            {t("onboarding.welcomeButton")}
            <span className="font-bold">TÆlor</span>
          </div>
        </button>
      </div>
    </div>
  )
}
