import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import localePtBR from "locales/pt-BR.json"
import localeEnUS from "locales/en-US.json"

const language = localStorage.getItem("Language")

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ["querystring", "navigator"],
      lookupQuerystring: "lng",
    },
    fallbackLng: language ? language : "en-US",
    resources: {
      pt: { translation: localePtBR },
      en: { translation: localeEnUS },
    },
    // lng: language,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
  })

export const i18nInstance = i18n
