import { Profile } from "./types"
import { i18nInstance } from "boot/i18n"

export const profiles: Profile[] = [
  {
    name: i18nInstance.t("onboarding.cardDirection.title"),
    type: "DIRECAO",
    summary: i18nInstance.t("onboarding.cardDirection.summary"),
    description: i18nInstance.t("onboarding.cardDirection.description"),
    icon: null,
    background: "onboarding/direcao",
  },
  {
    name: i18nInstance.t("onboarding.cardManagement.title"),
    type: "GERENCIA",
    summary: i18nInstance.t("onboarding.cardManagement.summary"),
    description: i18nInstance.t("onboarding.cardManagement.description"),
    icon: null,
    background: "onboarding/gerencia",
  },
  {
    name: i18nInstance.t("onboarding.cardAnalysis.title"),
    type: "ANALISE_OPERACOES",
    summary: i18nInstance.t("onboarding.cardAnalysis.summary"),
    description: i18nInstance.t("onboarding.cardAnalysis.description"),
    icon: null,
    background: "onboarding/analise_operacoes",
  },
]
