import { HTTPClient } from "client/HTTP"
import { Suggestion, SuggestionsResponse } from "types/Suggestions"
import { create } from "zustand"

const cache: { suggestions: Suggestion[] } = {
  suggestions: [],
}

type State = {
  cards: Suggestion[]
  setCards: () => void
}

async function getRandomSkills() {
  if (cache.suggestions.length === 0) {
    const data =
      await HTTPClient.get("skills/suggestion").json<SuggestionsResponse>()
    cache.suggestions = data
  }

  const selectedSkills = []
  const newArray = cache.suggestions.slice()

  for (let i = 0; i < 3; i++) {
    const indice = Math.floor(Math.random() * newArray.length)
    const elementoSelecionado = newArray.splice(indice, 1)[0]
    selectedSkills.push(elementoSelecionado)
  }

  return selectedSkills
}

export const useHomeStore = create<State>((set) => ({
  cards: [],
  setCards: async () => {
    const newCards = await getRandomSkills()
    set(() => ({ cards: newCards }))
  },
}))
