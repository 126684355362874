import { TbDeviceImacSearch } from "react-icons/tb"

export const FeedbackEmptyList = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <TbDeviceImacSearch size={32} className="mb-4 text-[#DEE4EA]" />
      <p className="mb-2 text-[#DEE4EA]">Nenhuma categoria foi selecionada</p>
      <p className="text-sm text-[#DEE4EA]">
        Filtre uma ou mais categorias para visualizar as perguntas aqui.
      </p>
    </div>
  )
}
