import { create } from "zustand"

export type OnboardingStep =
  | "STEP_WELCOME"
  | "STEP_PROFILE_CALIBRATION"
  | "STEP_INTEREST_FREQUENCY"

export type ProfileType = "GERENCIA" | "DIRECAO" | "ANALISE_OPERACOES" | "NONE"

type State = {
  selectedProfile: ProfileType
  currentStep: OnboardingStep
  setCurrentStep: (currentStep: OnboardingStep) => void
  setSelectedProfile: (selectedProfile: ProfileType) => void
}

export const useOnboardingStore = create<State>((set) => ({
  selectedProfile: "NONE",
  currentStep: "STEP_WELCOME",
  setCurrentStep: (currentStep: OnboardingStep) => set(() => ({ currentStep })),
  setSelectedProfile: (selectedProfile: ProfileType) =>
    set(() => ({ selectedProfile })),
}))
