import * as SelectPrimitive from "@radix-ui/react-select"
import { clsx } from "clsx"
import { SelectCategoriesProps } from "./types"
import { BsChevronDown, BsChevronUp } from "react-icons/bs"
import { useState } from "react"
import { FaMagnifyingGlass } from "react-icons/fa6"
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md"

export const SelectCategories = ({
  options,
  onChange,
  selectedCategories,
}: SelectCategoriesProps) => {
  const [value] = useState<string>("")

  return (
    <SelectPrimitive.Root
      defaultValue={value}
      value={value}
      onValueChange={(value) => onChange(value)}
    >
      <SelectPrimitive.Trigger asChild aria-label="Categorias">
        <div className="flex h-[40px] items-center rounded-lg bg-[#22272B] px-4 text-xs text-[#B6C2CF]">
          <div className="flex items-center gap-2">
            <SelectPrimitive.Icon className="ml-auto">
              <FaMagnifyingGlass />
            </SelectPrimitive.Icon>
            <SelectPrimitive.Value
              placeholder="Selecione a categoria"
              aria-label={value}
            />
          </div>
          <SelectPrimitive.Icon className="ml-auto">
            <BsChevronDown />
          </SelectPrimitive.Icon>
        </div>
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Content
        position="popper"
        sideOffset={10}
        side="bottom"
        className="w-[330px]"
      >
        <SelectPrimitive.ScrollUpButton className="flex items-center justify-center text-gray-700 dark:text-gray-300">
          <BsChevronUp />
        </SelectPrimitive.ScrollUpButton>
        <SelectPrimitive.Viewport className="rounded-lg bg-[#22272B] p-2 shadow-lg">
          <SelectPrimitive.Group>
            {options.map((f, i) => (
              <SelectPrimitive.Item
                key={`${f}-${i}`}
                value={f.value}
                className={clsx(
                  "relative flex items-center rounded-md px-8 py-2 text-sm text-[#B6C2CF] focus:bg-[#2C333A]",
                  "radix-disabled:opacity-50",
                  "select-none focus:outline-none"
                )}
              >
                {selectedCategories.some((cat) => cat === f.value) ? (
                  <div className="absolute left-2 inline-flex items-center">
                    <MdCheckBox className="text-[#579DFF]" />
                  </div>
                ) : (
                  <div className="absolute left-2 inline-flex items-center">
                    <MdCheckBoxOutlineBlank className="text-[#B6C2CF]" />
                  </div>
                )}
                <SelectPrimitive.ItemText>{f.label}</SelectPrimitive.ItemText>
              </SelectPrimitive.Item>
            ))}
          </SelectPrimitive.Group>
        </SelectPrimitive.Viewport>
        <SelectPrimitive.ScrollDownButton className="flex items-center justify-center text-gray-700 dark:text-gray-300">
          <BsChevronDown />
        </SelectPrimitive.ScrollDownButton>
      </SelectPrimitive.Content>
    </SelectPrimitive.Root>
  )
}
