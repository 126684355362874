import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { BsSend } from "react-icons/bs"
import { useOutletContext } from "react-router-dom"
import { ContextType } from "routes/root"
import { useLocation } from "react-router-dom"

type Props = {
  onSubmitMessage: Function
  loading: boolean
}

export default function ChatInput({ onSubmitMessage, loading }: Props) {
  const location = useLocation()
  const { isMobile } = useOutletContext<ContextType>()
  const { t } = useTranslation()

  const [input, setInput] = React.useState("")

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
  }

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      if (input.trim() === "") return
      if (loading) return
      e.preventDefault()

      onSubmitMessage(input.trim())

      setInput("")
    },
    [input, loading, onSubmitMessage]
  )

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const input = params.get("input")

    if (input) setInput(input)
  }, [location.search])

  // Handle submitting with enter
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isMobile) {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault()
          handleSubmit(e as any)
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [handleSubmit, isMobile])

  return (
    <div className={`bottom-0 px-4 pt-4 mb-6 flex w-full lg:mb-8`}>
      <form
        className="flex w-full items-end justify-center"
        onSubmit={handleSubmit}
      >
        <div
          className={`relative flex w-full flex-row items-center rounded-lg bg-[#22272B]`}
        >
          <input
            type="text"
            className="ml-[16px] h-[60px] w-full resize-none border-none bg-[#22272B] pr-4 text-white outline-none placeholder:text-white"
            onChange={handleChange}
            value={input}
            placeholder={t("chat.chatInputPlaceholder")}
          />
          <button
            type="submit"
            className="mr-4 flex size-[40px] shrink-0 cursor-pointer items-center justify-center rounded-full bg-[#282E33] p-2 text-xl text-white"
            disabled={input.trim() === ""}
          >
            {loading ? (
              <div className="mx-auto size-5 animate-spin rounded-full border-b-2 border-white" />
            ) : (
              <BsSend className="text-[#DEE4EA]" />
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
