import { ComponentPropsWithoutRef } from "react"
import { cn } from "utils/utils"

export const ButtonOnboarding = ({
  children,
  side = "left",
  ...props
}: ComponentPropsWithoutRef<"button"> & { side: "left" | "right" }) => {
  const css = cn(
    "flex h-[42px] min-w-[136px] items-center justify-center rounded-lg bg-[#0C66E4] text-[#F7F8F9] disabled:cursor-not-allowed disabled:bg-[#8590A2]",
    {
      "w-full xl:w-[340px]": side === "right",
      "bg-[#8590A2] w-[90px] xl:w-[136px]": side === "left",
    }
  )

  return (
    <button {...props} className={css}>
      {children}
    </button>
  )
}
