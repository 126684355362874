import React, { createContext, useCallback, useContext, useState } from "react"
import { Conversation } from "utils/History"
import { OpenAIChatMessage } from "utils/OpenAI"
import { useAuth } from "./AuthProvider"
import { Feedback } from "utils/Feedback"
import { useTranslation } from "react-i18next"

type ChatContextType = {
  currentChat?: Conversation
  history?: History
  loadChat: (id: string) => void
  newChat: () => void
  sendMessage: (msg: string) => Promise<Conversation>
  renameChat: (chat_id: string, msg: string) => Promise<any>
  sendFeedback: (
    chat_id: string,
    message_id: number,
    feedback: Feedback
  ) => Promise<any>
  loadHistory: () => Promise<History>
}

const ChatContext = createContext<ChatContextType | undefined>(undefined)

export function ChatProvider({ children }: { children: React.ReactNode }) {
  const [currentChat, setCurrentChat] = useState<Conversation>()
  const [history, setHistory] = useState<History>()
  const { token, initialized } = useAuth()
  const { t } = useTranslation()

  const cleanChat = useCallback(() => {
    const chat = {
      name: "",
      messages: [] as OpenAIChatMessage[],
    } as Conversation

    setCurrentChat(chat)
  }, [])

  const newChat = useCallback(() => {
    const params = new URLSearchParams(window.location.search)
    const assistantMessage = params.get("assistantMessage")

    const chat = {
      name: t("chat.newChat"),
      messages: [
        {
          role: "assistant",
          content: assistantMessage
            ? assistantMessage
            : t("chat.assistantMessage"),
        },
      ],
    } as Conversation

    setCurrentChat(chat)
  }, [t])

  const loadChat = useCallback(
    (id: string) => {
      if (!initialized) return
      if (token === "") return (window.location.href = "/login")

      cleanChat()

      fetch(`${process.env.REACT_APP_API_ENDPOINT}/chats/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401) {
              window.location.href = "/login"
            } else {
              throw new Error(`HTTP error! Status: ${response.status}`)
            }
          }

          return response.json()
        })
        .then((data) => {
          setCurrentChat(data)
        })
        .catch((error) => {
          throw new Error(error)
        })
    },
    [token, initialized, cleanChat]
  )

  const renameChat = useCallback(
    async (id: string, name: string) => {
      if (!initialized) return
      if (token === "") return (window.location.href = "/login")

      await fetch(`${process.env.REACT_APP_API_ENDPOINT}/chats/${id}/rename`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify({ name }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
          }

          return response.json()
        })
        .then(() => {
          setCurrentChat({
            ...currentChat,
            name,
          } as Conversation)
        })
        .catch((error) => {
          throw new Error(error)
        })
    },
    [currentChat, token, initialized]
  )

  const suggestTitle = useCallback(
    (chat_id: string) => {
      if (!initialized) return
      if (token === "") return (window.location.href = "/login")

      return fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/chats/${chat_id}/suggest_title`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      )
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401) {
              window.location.href = "/login"
            } else {
              throw new Error(`HTTP error! Status: ${response.status}`)
            }
          }

          return response.json()
        })
        .catch((error) => {
          throw new Error(error)
        })
    },
    [token, initialized]
  )

  const sendFeedback = useCallback(
    async (chat_id: string, message_id: number, feedback: Feedback) => {
      if (!initialized) return
      if (token === "") return (window.location.href = "/login")

      const payload: any = {
        chat_id: chat_id,
        message_id: message_id,
        rating: feedback.rating,
      }

      if (feedback.content) {
        payload.content = feedback.content
      }

      await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/chats/message_feedback`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(payload),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
          }

          return response.json()
        })
        .then((data) => {
          if (
            feedback.rating === "positive" ||
            (feedback.rating === "negative" && feedback.content)
          ) {
            const tempChat = {
              ...currentChat,
            } as Conversation

            const message = tempChat.messages.find((c) => c.id === message_id)

            if (message) {
              message.feedback = data

              tempChat.messages = tempChat.messages.map((c) => ({
                ...c,
                typingEffect: false,
              }))

              setCurrentChat(tempChat)
            }
          }
        })
        .catch((error) => {
          throw new Error(error)
        })
    },
    [currentChat, token, initialized]
  )

  const loadHistory = useCallback(async () => {
    if (!initialized) return
    if (token === "") return (window.location.href = "/login")

    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/chats`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            window.location.href = "/login"
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`)
          }
        }

        return response.json()
      })
      .then((data) => {
        setHistory(data)

        return data
      })
      .catch((error) => {
        throw new Error(error)
      })
  }, [token, initialized])

  const sendMessage = useCallback(
    async (msg: string): Promise<Conversation> => {
      if (!currentChat) throw new Error("currentChat not defined.")

      let chatId = currentChat.id

      const userMessage = {
        role: "user",
        content: msg,
      }

      const isNewChat = chatId === undefined

      const tempChat = {
        ...currentChat,
        messages: [...currentChat.messages, userMessage as OpenAIChatMessage],
        isNewChat,
      } as Conversation

      tempChat.messages = tempChat.messages.map((c) => ({
        ...c,
        typingEffect: false,
      }))

      setCurrentChat(tempChat)
      // conversation.messages.push(userMessage as OpenAIChatMessage)

      if (isNewChat) {
        const payload = {
          name: currentChat.name,
          messages: currentChat.messages,
        }

        await fetch(`${process.env.REACT_APP_API_ENDPOINT}/chats`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(payload),
        })
          .then((response) => {
            if (!response.ok) {
              if (response.status === 401) {
                window.location.href = "/login"
              } else {
                throw new Error(`HTTP error! Status: ${response.status}`)
              }
            }

            return response.json()
          })
          .then((data) => {
            chatId = data.id
          })
          .catch((error) => {
            throw new Error(error)
          })
      }

      if (!tempChat.id) {
        tempChat.id = chatId
      }

      const completion = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/chats/${chatId}/completion`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(userMessage),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
          }

          return response.json()
        })
        .catch((error) => {
          throw new Error(error)
        })

      completion.typingEffect = true

      tempChat.messages.push(completion as OpenAIChatMessage)

      setCurrentChat(tempChat)

      if (tempChat.isNewChat) {
        let title = await suggestTitle(tempChat.id)
        title = title.replaceAll('"', "")
        // .then(title => {
        //   renameChat(chatId, title);
        //   // setCurrentChat({ ...currentChat, name: title })
        // })
        tempChat.name = title
        await renameChat(chatId, title)
        loadHistory()
      }

      return tempChat
    },
    [currentChat, token, loadHistory, renameChat, suggestTitle]
  )

  return (
    <ChatContext.Provider
      value={{
        currentChat,
        history,
        loadChat,
        newChat,
        sendMessage,
        renameChat,
        sendFeedback,
        loadHistory,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export function useChat() {
  const context = useContext(ChatContext)
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider")
  }
  return context
}
