import useTenantClient from "client/Tenant"
import { useAuth } from "context/AuthProvider"
import { useCallback, useEffect, useState } from "react"
import { Outlet } from "react-router"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { Workspace, storeWorkspace } from "utils/Workspace"
import { parseJwt } from "utils/utils"

export type ContextType = {
  isMobile: boolean
  currentError: string
  setCurrentError: (error: string) => void
}

export default function Root() {
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [currentError, setCurrentError] = useState<string>("")
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const tenantsClient = useTenantClient()
  const navigate = useNavigate()

  const { addToken } = useAuth()

  const handleWindowSizeChange = useCallback(() => {
    setWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [handleWindowSizeChange])

  const isMobile = width <= 768

  useEffect(() => {
    const token = searchParams.get("token")

    if (token && token !== "") {
      addToken(token)

      const parsedToken = parseJwt(token)

      if (parsedToken) {
        tenantsClient()
          .fetchTenantParams(parsedToken.tenant)
          .then((result) => {
            const savedWorkspace = {
              key: result.Key,
              name: result.Name,
              url: result.Url,
            } as Workspace

            storeWorkspace(savedWorkspace)
          })
        // .catch(_ => setError("Invalid workspace"))
      }

      navigate(location.pathname)
    }
  }, [searchParams, addToken, navigate, location.pathname, tenantsClient])

  return (
    <>
      <Outlet
        context={
          {
            isMobile,
            currentError,
            setCurrentError,
          } satisfies ContextType
        }
      />
    </>
  )
}
