import useTenantClient from "client/Tenant"
import { useCallback, useState } from "react"
import { Workspace, storeWorkspace } from "utils/Workspace"

interface Props {
  onSubmitSuccess?: (workspace: Workspace) => void
}

export default function WorkspaceForm({ onSubmitSuccess }: Props) {
  const tenantsClient = useTenantClient()
  const [workspace, setWorkspace] = useState<string>("")

  const [loading, setLoading] = useState<boolean>(false)

  const [error, setError] = useState<string>()

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      setLoading(true)
      setError(undefined)

      tenantsClient()
        .fetchTenantParams(workspace.toLocaleLowerCase())
        .then((result) => {
          const savedWorkspace = {
            key: result.Key,
            name: result.Name,
            url: result.Url,
          } as Workspace

          storeWorkspace(savedWorkspace)

          if (onSubmitSuccess) onSubmitSuccess(savedWorkspace)
        })
        .finally(() => setLoading(false))
        .catch(() => setError("Invalid workspace"))
    },
    [workspace, tenantsClient, onSubmitSuccess]
  )

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkspace(e.target.value)
  }

  return (
    <form className="mt-10" onSubmit={handleSubmit}>
      <div className="flex flex-col">
        <input
          placeholder="Enter your workspace"
          className="mt-4 w-full min-w-[250px] rounded-xl border-none bg-taelor-800 p-4 text-taelor-200 outline-none"
          onChange={handleInput}
          value={workspace}
        />

        {error ? (
          <div className="ml-2 mt-2 flex w-full items-center text-red-400">
            <span>{error}</span>
          </div>
        ) : null}

        <div className="mt-4 self-center">
          <button
            className="rounded-lg bg-taelor-800 px-4 py-2 text-taelor-100 disabled:bg-taelor-800/40 disabled:text-taelor-100/40"
            type="submit"
            disabled={workspace.trim() === ""}
          >
            {loading ? (
              <div className="mx-auto size-5 animate-spin rounded-full border-b-2 border-white" />
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}
