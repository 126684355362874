import { useChat } from "context/ChatProvider"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Feedback, FeedbackRating } from "utils/Feedback"
import { TbFileLike, TbFileDislike } from "react-icons/tb"
import { BsSend } from "react-icons/bs"
import { MdCheck } from "react-icons/md"

type Props = {
  messageId: number
  feedback: Feedback | undefined
}

export default function ChatFeedback({ messageId, feedback }: Props) {
  const { t } = useTranslation()
  const [selectedFeedback, setSelectedFeedback] = useState<FeedbackRating>()
  const [input, setInput] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const { sendFeedback, currentChat } = useChat()
  const [hideTextArea, setHideTextArea] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
  }

  const handleFeedbackClick = (value: FeedbackRating) => {
    setSelectedFeedback(value)

    if (!currentChat) return

    sendFeedback(currentChat.id, messageId, { content: input, rating: value })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!currentChat) return

    setSubmitting(true)

    sendFeedback(currentChat.id, messageId, {
      content: input,
      rating: selectedFeedback,
    })
      .then(() => {
        setHideTextArea(true)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <>
      {feedback ? (
        <div className="mt-6 flex items-center text-sm text-[#2ABB7F]">
          <MdCheck size={14} />
          <span className="pl-2">{t("chat.feedbackSent")}</span>
        </div>
      ) : (
        <>
          <div className="mt-6 flex items-center justify-between text-xl">
            <p className="text-xs">A resposta foi útil?</p>
            <div className="flex gap-4">
              <button
                type="button"
                onClick={() => handleFeedbackClick("positive")}
              >
                <TbFileLike size={24} />
              </button>
              <button
                type="button"
                onClick={() => handleFeedbackClick("negative")}
              >
                <TbFileDislike size={24} />
              </button>
            </div>
          </div>
          {selectedFeedback === "negative" && !hideTextArea ? (
            <form onSubmit={handleSubmit}>
              <div className="relative mt-4 flex w-full flex-row items-center rounded-lg bg-[#22272B]">
                <input
                  autoFocus
                  className="ml-[16px] h-[60px] w-full resize-none border-none bg-[#22272B] pr-4 text-white outline-none placeholder:text-white"
                  onChange={handleChange}
                  value={input}
                  placeholder={t("chat.feedbackPlaceholder")}
                />
                <button
                  type="submit"
                  className="mr-4 flex size-[40px] shrink-0 cursor-pointer items-center justify-center rounded-full bg-[#282E33] p-2 text-xl text-white"
                  disabled={input.trim() === ""}
                >
                  {submitting ? (
                    <div className="mx-auto size-5 animate-spin rounded-full border-b-2 border-white" />
                  ) : (
                    <BsSend className="text-[#DEE4EA]" />
                  )}
                </button>
              </div>
            </form>
          ) : null}
        </>
      )}
    </>
  )
}
