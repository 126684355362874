const WORKSPACE_KEY = "taelor-workspace"

// Types
export type Workspace = {
  key: string
  name: string
  url: string
}

export const storeWorkspace = (workspace: Workspace) => {
  localStorage.setItem(WORKSPACE_KEY, JSON.stringify(workspace))
}

export const getWorkspace: () => Workspace = () => {
  const workspace = localStorage.getItem(WORKSPACE_KEY)
  return workspace ? JSON.parse(workspace) : undefined
}

// Clear conversations from local storage
export const clearWorkspace = () => {
  localStorage.removeItem(WORKSPACE_KEY)
}
