import { Link } from "react-router-dom"
import { Chat } from "types/Chat"
import { TbDoorExit } from "react-icons/tb"
import { useChats } from "hooks/useChats"

type DateGroup = "TODAY" | "CURRENT_MONTH" | string

export const ChatHistory = () => {
  const { data } = useChats()

  function getPeriod(timestamp: number): DateGroup {
    const chatDate = new Date(timestamp * 1000)
    const now = new Date()

    if (
      chatDate.getDate() === now.getDate() &&
      chatDate.getMonth() === now.getMonth() &&
      chatDate.getFullYear() === now.getFullYear()
    ) {
      return "TODAY"
    } else if (
      chatDate.getFullYear() === now.getFullYear() &&
      chatDate.getMonth() === now.getMonth()
    ) {
      return "CURRENT_MONTH"
    } else if (
      chatDate.getFullYear() === now.getFullYear() &&
      chatDate.getMonth() !== now.getMonth()
    ) {
      const month = [
        "JANUARY",
        "FEBRUARY",
        "MARCH",
        "APRIL",
        "MAY",
        "JUNE",
        "JULY",
        "AUGUST",
        "SEPTEMBER",
        "OCTOBER",
        "NOVEMBER",
        "DECEMBER",
      ]

      return month[chatDate.getMonth()]
    }

    return "LAST_YEAR"
  }

  const groupedChats: Record<DateGroup, Chat[]> = {
    TODAY: [],
    CURRENT_MONTH: [],
    JANUARY: [],
    FEBRUARY: [],
    MARCH: [],
    APRIL: [],
    MAY: [],
    JUNE: [],
    JULY: [],
    AUGUST: [],
    SEPTEMBER: [],
    OCTOBER: [],
    NOVEMBER: [],
    DECEMBER: [],
  }

  data?.forEach((item) => {
    const period = getPeriod(item.last_message.created_at)
    groupedChats[period]?.push(item)
  })

  const { TODAY, CURRENT_MONTH, ...rest } = groupedChats

  // TODO: Usar i18n
  const getMonthLabel = (index: number) => {
    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ]

    return months[index]
  }

  return (
    <div className="flex h-[80vh] flex-col">
      <div className="w-full overflow-y-scroll scrollbar-none">
        {TODAY.length > 0 && (
          <div>
            <p className="mb-3 text-xs text-[#9FADBC]">Hoje</p>
            {groupedChats.TODAY.map((chat) => (
              <Link key={chat.id} to={`/chat/${chat.id}`}>
                <p
                  className="mb-4 cursor-pointer truncate text-sm text-[#C7D1DB]"
                  title={chat.name}
                >
                  {chat.name}
                </p>
              </Link>
            ))}
          </div>
        )}
        {CURRENT_MONTH.length > 0 && (
          <div>
            <p className="mb-3 text-xs text-[#9FADBC]">Últimos 30 dias</p>
            {groupedChats.CURRENT_MONTH.map((chat) => (
              <Link key={chat.id} to={`/chat/${chat.id}`}>
                <p
                  className="mb-4 cursor-pointer truncate text-sm text-[#C7D1DB]"
                  title={chat.name}
                >
                  {chat.name}
                </p>
              </Link>
            ))}
          </div>
        )}
        {Object.values(rest).length > 0 && (
          <>
            {Object.values(rest)
              .filter((month) => month.length > 0)
              .map((month, index) => (
                <div key={index}>
                  <p className="mb-3 text-xs text-[#9FADBC]">
                    {getMonthLabel(index)}
                  </p>
                  {month.map((chat) => (
                    <Link key={chat.id} to={`/chat/${chat.id}`}>
                      <p
                        className="mb-4 cursor-pointer truncate text-sm text-[#C7D1DB]"
                        title={chat.name}
                      >
                        {chat.name}
                      </p>
                    </Link>
                  ))}
                </div>
              ))}
          </>
        )}
      </div>
      <div className="mt-auto cursor-pointer pt-4">
        <Link to="/login" className="flex items-center gap-2">
          <TbDoorExit className="text-[#E9F2FF]" size={24} />
          <p className="text-[#9FADBC]">Sair</p>
        </Link>
      </div>
    </div>
  )
}
