import ky from "ky"

export const HTTPClient = ky.create({
  prefixUrl: process.env.REACT_APP_API_ENDPOINT,
  hooks: {
    beforeRequest: [
      (request) => {
        const token = localStorage.getItem("token")
        request.headers.set("Authorization", `Bearer ${token}`)
      },
    ],
  },
})
