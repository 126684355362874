import { MdArrowCircleRight } from "react-icons/md"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"

type Props = {
  content: string | ReactNode
}

export default function BonusTip({ content }: Props) {
  const { t } = useTranslation()

  return (
    <div className="relative mt-4 max-w-[500px] font-sans">
      <div className="text-md mb-2 ml-2 font-medium uppercase text-taelor-50">
        {t("chat.bonusTip")}
      </div>
      <div className="rounded-xl bg-[#FFB801] p-4 pr-12 text-[#191A1D]">
        {content}
      </div>
      <div className="absolute bottom-2 right-2 text-4xl text-[#191A1D]">
        <MdArrowCircleRight />
      </div>
    </div>
  )
}
