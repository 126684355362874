import { ReactNode } from "react"
import { HomeLayoutHeader } from "./components/Header"
import { MobileSidebarHistory } from "./components/MobileSidebarHistory"
import { useMediaQuery } from "usehooks-ts"
import { DesktopSidebarHistory } from "./components/DesktopSidebarHistory"
import { SidebarQuestionsList } from "./components/SidebarQuestionsList"
import ProfilePicture from "components/profile_picture/ProfilePicture"
import { useUser } from "context/UserProvider"
import { User } from "utils/User"
import { useAuthenticatedLayoutStore } from "store/auth-layout/useAuthenticatedLayoutStore"
import { Link } from "react-router-dom"

export const AuthenticatedLayout = ({ children }: { children: ReactNode }) => {
  const user = useUser()
  const menu = useAuthenticatedLayoutStore((state) => state.menu)
  const isMobile = useMediaQuery("(max-width: 1024px)")

  const LeftSidebar = isMobile ? MobileSidebarHistory : DesktopSidebarHistory

  return (
    <div className="flex max-h-full min-h-full flex-col items-center justify-center overflow-x-hidden bg-gradient-to-b from-[#16339E] to-[#161A1D] to-15% lg:border lg:border-[#1D2125] lg:px-6 lg:pb-6">
      {menu === "LIST_OF_QUESTIONS" && (
        <div className="fixed left-0 top-0 z-10 h-full w-screen bg-black/50"></div>
      )}
      <div className="mb-5 mt-4 hidden w-full items-center justify-between lg:flex">
        <div className="flex w-[170px] items-center gap-2">
          <Link to="/">
            <img
              src="/taelor-logo.svg"
              alt="Taelor logo"
              className="w-[103px]"
            />
          </Link>
          <div className="flex h-[21px] items-center justify-center rounded-md bg-[#3F8CFE] px-1 text-xs text-white">
            beta
          </div>
        </div>
        <ProfilePicture user={user.currentUser as User} />
      </div>
      <div className="flex size-full justify-start gap-12">
        <LeftSidebar />
        <div className="relative flex size-full max-h-full grow flex-col items-center justify-center bg-home/radial-gradient lg:rounded-2xl lg:border lg:border-[#1D2125] lg:bg-home/lg-radial-gradient">
          <HomeLayoutHeader />
          <SidebarQuestionsList />
          <main className="flex h-full grow flex-col items-center justify-center">
            {children}
          </main>
        </div>
      </div>
    </div>
  )
}
