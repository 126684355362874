import ChatMessages from "components/chat/ChatMessages"
import { AuthenticatedLayout } from "components/layouts/AuthenticatedLayout"
import { useChat } from "context/ChatProvider"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

export default function Chat() {
  const { loadChat, newChat } = useChat()

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      loadChat(id)
      return
    } else {
      newChat()
    }
  }, [id, loadChat, newChat])

  return (
    <AuthenticatedLayout>
      <ChatMessages />
    </AuthenticatedLayout>
  )
}
