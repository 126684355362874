import { useState, useEffect } from "react"

import { VideoDescriptionProps } from "./types"

const ProgressBar = ({ totalTime }: { totalTime: number }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const elapsed = Math.min(progress + 100 / totalTime, 100)
      setProgress(elapsed)

      if (elapsed >= 100) {
        clearInterval(intervalId)
      }
    }, 100)

    return () => clearInterval(intervalId)
  }, [progress, totalTime])

  return (
    <div>
      <div
        style={{
          width: `${progress}%`,
          height: "2px",
          position: "absolute",
          bottom: 0,
          left: 0,
          background:
            "linear-gradient(90deg, #0F6FFE 49.99%, rgba(15, 111, 254, 0.00) 87.33%)",
          transition: "width 0.1s",
        }}
      ></div>
    </div>
  )
}

export const VideoDescription = ({
  title,
  description,
  icon,
  time,
}: VideoDescriptionProps) => {
  return (
    <div className="relative flex w-full items-start gap-4 bg-[#161A1D] p-4 lg:rounded-lg">
      <img src={icon} alt="" className="size-6" />
      <div className="text-white">
        <p className="font-medium">{title}</p>
        <p>{description}</p>
      </div>
      <ProgressBar totalTime={time} />
    </div>
  )
}
