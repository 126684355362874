import { OpenAIChatMessage } from "utils/OpenAI"
import AssistantMessageContent from "./AssistantMessageContent"
import UserMessageContent from "./UserMessageContent"

type Props = {
  message: OpenAIChatMessage
  scrollFn?: () => void
}

export default function ChatMessage({ message, scrollFn }: Props) {
  return (
    <div className={`flex flex-row items-center transition-all`}>
      {message.role === "user" ? (
        <UserMessageContent content={message.content} />
      ) : (
        <AssistantMessageContent message={message} scrollFn={scrollFn} />
      )}
    </div>
  )
}
