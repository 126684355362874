import { useOnboardingStore } from "store/onboarding/useOnboardingStore"
import { CardProfileProps } from "./types"
import { cn } from "utils/utils"

export const CardProfile = ({
  name,
  summary,
  description,
  type,
}: CardProfileProps) => {
  const selectedProfile = useOnboardingStore((state) => state.selectedProfile)
  const setSelectedProfile = useOnboardingStore(
    (state) => state.setSelectedProfile
  )

  const handleOnClick = () => {
    setSelectedProfile(type)
  }

  const backgroundCSS = cn(
    `flex text-white w-[235px] lg:w-full h-[315px] lg:h-[400px] p-6 lg:p-10 pt-[40px] rounded-md relative cursor-pointer before:absolute before:content-[''] before:bg-contain before:bg-no-repeat before:bg-center before:top-0 before:left-0 before:w-full before:h-full hover:before:grayscale-0`,
    {
      "before:grayscale": selectedProfile !== type,
      "before:bg-onboarding/direcao": type === "DIRECAO",
      "before:bg-onboarding/gerencia": type === "GERENCIA",
      "before:bg-onboarding/analise_operacoes": type === "ANALISE_OPERACOES",
    }
  )

  const descriptionCSS = cn("text-sm", {
    hidden: selectedProfile !== type,
  })

  const summaryCSS = cn("text-sm", {
    "pb-8 lg:pb-12": selectedProfile === type,
    "pb-8": selectedProfile !== type,
  })

  return (
    <div onClick={handleOnClick}>
      <div className={backgroundCSS}>
        <div className="relative self-end">
          <img
            src={`onboarding/icon-${type.toLowerCase()}.svg`}
            alt=""
            className="mb-2 size-[22px]"
          />
          <p className="pb-1 text-2xl font-semibold">{name}</p>
          <p className={summaryCSS}>{summary}</p>
          <p className={descriptionCSS}>{description}</p>
        </div>
      </div>
    </div>
  )
}
