import Cogtive from "components/icons/Cogtive"
import Taelor from "components/icons/Taelor"
import { useAuth } from "context/AuthProvider"
import { useUser } from "context/UserProvider"
import { useCallback, useEffect, useState } from "react"
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom"
import WorkspaceForm from "./WorkspaceForm"
import { Workspace, clearWorkspace, getWorkspace } from "utils/Workspace"
import { ContextType } from "routes/root"
import { AccessDeniedException } from "utils/errors"

export default function Login() {
  const { addToken, clearToken } = useAuth()
  const { clearCurrentUser } = useUser()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [loadingTaelorToken, setLoadingTaelorToken] = useState(false)
  const { currentError, setCurrentError } = useOutletContext<ContextType>()

  const [workspace, setWorkspace] = useState<Workspace>()

  const getTaelorToken = useCallback(
    (customerEndpoint: string, cgtvToken: string) => {
      let url = `${customerEndpoint.replace(
        ":3001",
        ":5000"
      )}/api/v2/autorizar/taelor`

      if (!(url.startsWith("http://") || url.startsWith("https://"))) {
        url =
          process.env.NODE_ENV === "production"
            ? "https://" + url
            : "http://" + url
      }

      return fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Token": cgtvToken,
          Authorization: "Bearer f0cde4b4d0c34231917bc4042f2fd9d8",
        },
        method: "GET",
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 403) {
              throw new AccessDeniedException()
            }
            throw new Error(`HTTP error! Status: ${response.status}`)
          }

          return response.text()
        })
        .then((data) => {
          return data
        })
    },
    []
  )

  useEffect(() => {
    clearToken()
    clearCurrentUser()

    const cgtvToken = searchParams.get("Token")

    if (cgtvToken) {
      const decodedToken = atob(cgtvToken)

      const currentWorkspace = getWorkspace()

      setLoadingTaelorToken(true)

      getTaelorToken(currentWorkspace.url, decodedToken)
        .then((token) => {
          addToken(token)
          navigate("/")
        })
        .catch((e) => {
          if (e instanceof AccessDeniedException) {
            setCurrentError(e.message)
          }
        })
        .finally(() => setLoadingTaelorToken(false))
    }
  }, [
    setLoadingTaelorToken,
    setCurrentError,
    addToken,
    clearCurrentUser,
    clearToken,
    getTaelorToken,
    navigate,
    searchParams,
  ])

  useEffect(() => {
    if (!workspace) {
      const storedWorkspace = getWorkspace()

      if (storedWorkspace) setWorkspace(storedWorkspace)
    }
  }, [workspace])

  const handleBtnClick = () => {
    handleWorkspaceSubmit(workspace)
  }

  const handleChangeWorkspaceBtn = () => {
    clearWorkspace()
    setWorkspace(undefined)
  }

  const handleWorkspaceSubmit = (workspace: any) => {
    if (workspace && workspace.url) {
      if (process.env.NODE_ENV === "production") {
        return (window.location.href = `https://${workspace.url}?redirectUrl=${window.location.href}`)
      }

      window.location.href = `http://${workspace.url}?redirectUrl=${window.location.href}`
    }
  }

  return (
    <main className="max-w-screen relative flex max-h-screen min-h-screen w-screen flex-col overflow-hidden bg-taelor-900 text-taelor-400  antialiased">
      {currentError && currentError !== "" ? (
        <div className="text-md absolute inset-x-0 top-0 z-50 mx-auto max-w-lg rounded-b-lg bg-red-500 px-4 py-2 text-center text-taelor-50">
          {currentError}
        </div>
      ) : null}

      <div className="flex grow flex-col overflow-x-hidden bg-taelor-home bg-cover bg-center bg-no-repeat pt-10">
        <div className="flex h-screen w-full items-center justify-center">
          <div className="flex items-center justify-center ">
            <div className="flex flex-col items-center">
              <Taelor />
              <Cogtive className="mt-6" />

              {loadingTaelorToken ? (
                <div className="mx-auto mt-10 size-10 animate-spin rounded-full border-b-2 border-white" />
              ) : workspace ? (
                <div className="flex flex-col">
                  <button
                    className="font-base mt-12 rounded-3xl bg-taelor-700 px-10 py-2 text-lg text-taelor-50"
                    onClick={handleBtnClick}
                  >
                    Continue as {workspace.name}
                  </button>

                  <button
                    className="mt-4 text-taelor-400 underline"
                    onClick={handleChangeWorkspaceBtn}
                  >
                    or change to another Workspace
                  </button>
                </div>
              ) : (
                <WorkspaceForm onSubmitSuccess={handleWorkspaceSubmit} />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
