import Chat from "routes/chat"
import Home from "routes/home"
import Root from "routes/root"
import Login from "./login"
import Onboarding from "./onboarding"

const routes = [
  {
    path: "/",
    element: <Root />,
    children: [{ index: true, element: <Home /> }],
  },
  {
    path: "/chat",
    element: <Root />,
    children: [
      { index: true, element: <Chat /> },
      {
        path: ":id",
        element: <Chat />,
      },
    ],
  },
  {
    path: "/login",
    element: <Root />,
    children: [{ index: true, element: <Login /> }],
  },
  {
    path: "/welcome",
    element: <Root />,
    children: [{ index: true, element: <Onboarding /> }],
  },
]

export default routes
