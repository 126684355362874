import { Link } from "react-router-dom"

import { TbManualGearbox } from "react-icons/tb"
import { FiArrowDownRight } from "react-icons/fi"

import { CardSkillProps } from "./types"

export const CardSkill = ({ card }: CardSkillProps) => {
  return (
    <Link
      to={`/chat?input=${encodeURIComponent(card.taelorInput)}&assistantMessage=${encodeURIComponent(card.assistantMessage)}`}
    >
      <div className="group flex h-[265px] w-[245px] cursor-pointer flex-col rounded-xl border border-[#388BFF] bg-home/bg-card-skill pt-6 lg:h-[310px] lg:w-[285px]">
        <div className="mb-10 flex items-center gap-2 px-4">
          <div className="flex size-[32px] items-center justify-center rounded-lg bg-[#282E33] text-white">
            <TbManualGearbox />
          </div>
          <p className="text-xs font-medium uppercase text-[#DEE4EA]">
            {card.header}
          </p>
        </div>
        <div className="px-4">
          <p className="mb-[18px] text-sm text-[#DEE4EA]">{card.text1}</p>
          <p className="mb-2 text-[20px] font-bold text-[#DEE4EA]">
            {card.category}
          </p>
          <p className="text-sm text-[#B6C2CF]">{card.text2}</p>
        </div>
        <div className="mt-auto hidden h-[56px] w-full items-center justify-between rounded-b-xl bg-[#22272B] px-4 lg:group-hover:flex">
          <p className="text-sm text-[#C7D1DB]">Mais detalhes</p>
          <FiArrowDownRight size={24} className="text-[#E9F2FF]" />
        </div>
      </div>
    </Link>
  )
}
